<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Stock Request</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="stockRequestInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>User Request</label>
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    v-model="form.request_by"
                  />
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Sender</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.sender"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Receiver</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.receiver"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Product Name</th>
                      <th scope="col">Stock Request</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(stock, index) in stocks" :key="index">
                      <td>{{ stock.name_product }}</td>
                      <td>{{ stock.stock }}/{{ stock.name_unit }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="form-group">
                  <label>Status</label>
                  <select class="form-control" v-model="form.status">
                    <option value="">Select Status</option>
                    <option
                      :value="index"
                      v-for="(status, index) in statuses"
                      :key="index"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Note</label>
                  <textarea
                    v-model="form.note"
                    class="form-control"
                    style="height:100px"
                  ></textarea>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "FormPaymentType",
  data() {
    return {
      name: "Detail Product Stock Request",
      form: {
        note: "",
        status: "",
        sender: "",
        receiver: "",
        request_by: "",
      },
      statuses: [],
      stocks: [],
      isSubmitting: false,
      errors: {},
      edit: false,
    };
  },
  mounted() {
    this.getData();
    this.getStatus();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("stock_request/" + id)
        .then((result) => {
          this.form.note = result.data.data.note;
          this.form.status = result.data.data.status;
          this.form.sender = result.data.data.sender_name;
          this.form.receiver = result.data.data.receiver_name;
          this.form.request_by = result.data.data.created_by_name;
          this.stocks = result.data.data.stocks_in;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatus() {
      apiGet("stock_request/get_status").then((result) => {
        this.statuses = result.data;
        console.log(result);
      });
    },
    stockRequestInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "stock_request/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Product Stock Request has been created!");
            this.$router.push({ name: "ProductStockRequest" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(
            this.$store.state.api + "stock_request/update/" + id,
            this.form,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$noty.success("Your stockRequest has been updated!");
            this.$router.push({ name: "ProductStockRequest" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
